var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    {
      staticClass: "d-flex efm-footer p-0 m-0 flex-wrap flex-row",
      staticStyle: { position: "relative" },
    },
    [
      _c("div", { staticClass: "left-block" }, [
        _c("div", [
          _c("h2", [
            _vm._v(
              _vm._s(_setup.footer.officeName) +
                " | " +
                _vm._s(_setup.footer.officeCompanyName)
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_setup.footer.officeStreet) +
                ", " +
                _vm._s(_setup.footer.officePostalCode) +
                " " +
                _vm._s(_setup.footer.officeCity)
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_setup.officeContactInformation) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _setup.footer.officeGisaNumber
            ? _c("p", [
                _vm._v(
                  "\n        GISA: " +
                    _vm._s(_setup.footer.officeGisaNumber) +
                    " - Auszug aus dem Vermittlerregister:\n        www.gisa.gv.at/versicherungsvermittlerregister\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _setup.footer.officeFbNumber
                    ? "FB-Nr.: " + _setup.footer.officeFbNumber + "; "
                    : ""
                ) +
                "\n        Gerichtsstand: Österreich - Sitz des Vermittlers\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("h2", [
            _vm._v(
              "\n        " +
                _vm._s(_setup.footer.headquartersPositioning) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_setup.footer.headquartersAdText) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_setup.footer.headquartersComplaintOffice))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right-block" }, [
        _c("div", [
          _c("h2", [
            _vm._v(
              "\n        " + _vm._s(_setup.footer.headquartersName) + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_setup.footer.headquartersStreet) +
                ", " +
                _vm._s(_setup.footer.headquartersPostalCode) +
                "\n        " +
                _vm._s(_setup.footer.headquartersCity) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_setup.headquarterContactInformation) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(" ")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("GISA: " + _vm._s(_setup.footer.headquartersGisaNumber)),
          ]),
          _vm._v(" "),
          _setup.footer.headquartersFbNumber
            ? _c("p", [
                _vm._v("FB-NR.: " + _vm._s(_setup.footer.headquartersFbNumber)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.footer.headquartersJurisdiction
            ? _c("p", [
                _vm._v(
                  "\n        Gerichtsstand: " +
                    _vm._s(_setup.footer.headquartersJurisdiction) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }