var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PrintPage",
    {
      attrs: { pagenum: _vm.pagenum, maxpages: _vm.maxpages },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("ReportHeader", {
                attrs: {
                  logo: _vm.logo,
                  showEdit: _vm.showEdit,
                  textFields: _vm.textFields,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "main",
        [
          _c("div", { staticClass: "page-title-wrapper mb-3" }, [
            _c("h2", { staticClass: "report-title mb-2" }, [
              _vm._v("Risiko Check Übersicht"),
            ]),
          ]),
          _vm._v(" "),
          _c("ColorLegendV2", {
            attrs: { textFields: _vm.textFields, showInfo: false },
          }),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.customer, function (c, index) {
              return _c(
                "div",
                { key: _vm.pagenum + "customer" + index, staticClass: "col-6" },
                [
                  _c("ReportCustomerInfo", {
                    attrs: {
                      customer: c,
                      percentage: _vm.calcPercentageForCustomer(c),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-end align-items-end",
                    },
                    [
                      _c("CircleChart", {
                        staticStyle: { flex: "2" },
                        attrs: {
                          small: false,
                          val: _vm.calcPercentageForCustomer(c),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }