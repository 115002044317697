import { ICustomer } from '@/shared/model/customer.model';
import { Answer, IAnswer } from '@/shared/model/answer.model';
import { ICategory } from '@/shared/model/category.model';
import { IInsurance } from '@/shared/model/insurance.model';
import { InsuranceOption } from '@/shared/model/insurance-option.model';
import { TextFieldType } from '@/shared/model/text-field-setting.model';

const PERCENTAGE_FACTOR = 100;

//optional later database managed entity:
const COLOR_CODING = [
  { from: 0, to: 0.2, color: '#BD1822', name: TextFieldType.COLOR_LEGEND_RED },
  { from: 0.2, to: 0.4, color: '#E8501D', name: TextFieldType.COLOR_LEGEND_RED_ORANGE },
  { from: 0.4, to: 0.533, color: '#F7B032', name: TextFieldType.COLOR_LEGEND_ORANGE },
  { from: 0.533, to: 0.666, color: '#FAE818', name: TextFieldType.COLOR_LEGEND_YELLOW },
  { from: 0.666, to: 0.8, color: '#DBD900', name: TextFieldType.COLOR_LEGEND_YELLOW_GREEN },
  { from: 0.8, to: 1, color: '#42A935', name: TextFieldType.COLOR_LEGEND_GREEN },
];

/**
 * Calculates the percentage for a given customer based on the not ignored answers
 * @param answers all answers
 * @param customer customer
 * @returns percentage
 */
export function calculatePercentageForCustomer(answers: IAnswer[], customer: ICustomer): number {
  const answersForCustomer = findNotIgnoredAnswersForCustomer(answers, customer);
  return calculateSumWeightedValue(answersForCustomer);
}

/**
 * Returns all answers for a given customer that are not ignored
 * @param answers
 * @param customer
 */
export function findNotIgnoredAnswersForCustomer(
  answers: IAnswer[],
  customer: ICustomer
): IAnswer[] {
  if (!answers || !customer) {
    return [];
  }
  return answers.filter(item => item.customer.id === customer.id && !item.ignored);
}

export function calculateSumWeightedValue(answers: IAnswer[]): number {
  let sum = 0;
  answers.forEach(item => {
    sum += item.weightedValue;
  });
  return sum * PERCENTAGE_FACTOR;
}

/**
 * Returns a color hex code for a given percentage
 * @param percentage
 */
export function getColorHexFromPercentage(percentage: number): string {
  if (percentage == null) {
    return '#ffffff';
  }
  let pct = percentage / PERCENTAGE_FACTOR;
  //make sure the percentage is in the range of 0 to 1
  pct = Math.min(Math.max(0, pct), 1);

  for (const element of COLOR_CODING) {
    if (pct >= element.from && pct <= element.to) {
      return element.color;
    }
  }
  return '#ffffff';
}

/**
 *
 * @returns array of name and color for the color legend
 */
export function getNameColorItem(): { name: string; color: string }[] {
  //sort desc (green first)
  return COLOR_CODING.sort((a, b) => b.to - a.to).map(item => {
    return { name: item.name, color: item.color };
  });
}

export function calculateWeightedValue(
  savedAnswers: IAnswer[],
  customer: ICustomer,
  category: ICategory,
  percentageValue: number
): number {
  //find insurances
  const insuranceCount = savedAnswers.filter(
    item =>
      item.categoryDescription === category.description &&
      item.customer.id === customer.id &&
      item.ignored === false
  ).length;
  const weight = category.weight / insuranceCount / PERCENTAGE_FACTOR;
  //used for category based weight calculation
  return (weight * percentageValue) / PERCENTAGE_FACTOR;
}

/**
 * create ignored answer object from given params (no option selected->ignored)
 * @param insurance
 * @param customer
 */
export function createIgnoredAnswer(insurance: IInsurance, customer: ICustomer): Answer {
  const ans = new Answer();
  ans.customer = customer;
  ans.categoryDescription = insurance.category.description;
  ans.categoryOrder = insurance.category.orderNumber;
  ans.insuranceDescription = insurance.description;
  //set ignored flag
  ans.ignored = true;
  ans.selectedPercentage = null;
  ans.weightedValue = null;
  return ans;
}

/**
 * create answer object from given params
 * @param insurance selected insurance
 * @param customer selected customer
 * @param opt selected option
 */
export function createAnswer(
  insurance: IInsurance,
  customer: ICustomer,
  opt: InsuranceOption
): Answer {
  const ans = new Answer();
  ans.customer = customer;
  ans.categoryDescription = insurance.category.description;
  ans.categoryOrder = insurance.category.orderNumber;
  ans.insuranceDescription = insurance.description;
  ans.insuranceOptionDescription = opt.description;
  ans.selectedPercentage = opt.percentage.value;
  ans.lowPercentage = opt.warning;
  //if  warning is true, set comment
  if (opt.warning) {
    ans.comment = opt.defaultComment;
  }
  ans.ignored = false;
  return ans;
}
