var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.textFields
    ? _c(
        "div",
        {
          staticClass: "text-center d-flex justify-content-center",
          class: "direction-" + _setup.direction,
        },
        _vm._l(_setup.colors, function (col, index) {
          return _c(
            "div",
            { key: "col_" + index, staticClass: "color-legend" },
            [
              _setup.findLabel(col.name)
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "color-legend-wrapper",
                        staticStyle: { "font-size": "10px" },
                        style: "color: " + col.color,
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "color-legend-preview",
                          attrs: { icon: "circle" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_setup.findLabel(col.name).value))]),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }