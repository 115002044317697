var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "text-field-wrapper" },
      [
        _c("p", {
          staticStyle: { "white-space": "pre-wrap" },
          domProps: { innerHTML: _vm._s(_setup.letterFooter?.value) },
        }),
        _vm._v(" "),
        _c(_setup.TextFieldEditButton, {
          attrs: {
            showEdit: _vm.showEdit,
            "text-field-to-edit": _setup.letterFooter,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }