var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.logoUrl
    ? _c("img", {
        attrs: {
          alt: _vm.alt,
          height: _vm.height,
          src: _setup.logoUrl,
          width: _vm.width,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }