/**
 * Model for Customer
 */
export interface ICustomer {
  id: number | null;
  fremdId: number | null;
  lastName: string;
  firstName: string;
  dob: string | null;
  street: string | null;
  zip: string | null;
  city: string | null;
  job: string | null;
  anrede: string | null;
  title: string | null;
  titleAfter: string | null;
  mandantId: number | null;
  betreuerFremdId: number | null;
  //used to keep track of the "mainCustomer"
  isMainCustomer: boolean;
  // releationship to selected main customer , is null for main customer and only set for sub customers
  relationship: string | null;
}

export class Customer implements ICustomer {
  constructor(
    public id: number = null,
    public fremdId: number | null = null,
    public lastName: string = '',
    public firstName: string = '',
    public dob: string | null = null,
    public street: string | null = null,
    public zip: string | null = null,
    public city: string | null = null,
    public job: string | null = null,
    public anrede: string | null = null,
    public title: string | null = null,
    public titleAfter: string | null = null,
    public mandantId: number | null = null,
    public betreuerFremdId: number | null = null,
    public isMainCustomer: boolean = false,
    public relationship: string | null = null
  ) {}
}
