
import { CustomTextField, ICustomTextField } from "@/shared/model/custom-text-field.model";
import AlertService from "@/shared/alert/alert.service";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import CustomTextFieldService from "@/entities/custom-text-field/custom-text-field.service";
import TextFieldDialog from "@/components/text-field-dialog.vue";

@Component({
  components: {
    TextFieldDialog
  }
})
export default class TextFieldEditButton extends Vue {
  showModal = false;
  modalKey = 0;
  initialText = "";

  @Inject("alertService")
  public alertService: () => AlertService;
  @Inject("customTextFieldService")
  public customTextFieldService: () => CustomTextFieldService;

  @Prop({ type: Boolean, default: true })
  public showEdit;
  @Prop()
  public textFieldToEdit: CustomTextField | null;

  editTextField() {
    this.showModal = true;
    this.modalKey++;
    this.initialText = this.textFieldToEdit.value;
  }

  handleCancel() {
    this.showModal = false;
  }

  async handleUpdate(updatedText: string): Promise<void> {
    const updatedTextField: ICustomTextField = { ...this.textFieldToEdit, value: updatedText };
    this.updateTextField(updatedTextField);
  }

  async updateTextField(updatedTextField: ICustomTextField): Promise<void> {
    if (updatedTextField.id) {
      //update customTextFieldSetting update
      try {
        const result = await this.customTextFieldService().update(updatedTextField);
        updatedTextField.summaryReport = result.summaryReport;
        this.alertService().showSuccess(this, "Textfeld erfolgreich aktualisiert");
      } catch (e) {
        this.alertService().showError(this, "Fehler beim Aktualisieren des Textfeldes");
        this.showModal = false;
        return;
      }
    }
    //update store
    this.$store.commit("updateTextField", updatedTextField);
    this.showModal = false;
  }
}
