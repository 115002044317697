import { IAppUser } from '@/shared/model/app-user.model';

export interface ITextFieldSetting {
  value: string;
  id?: number;
  type?: TextFieldType;
  user?: IAppUser;
}

export class TextFieldSetting implements ITextFieldSetting {
  constructor(
    public value: string,
    public id?: number,
    public type?: TextFieldType,
    public user?: IAppUser
  ) {}
}

/**
 * enum to define type of custom text field
 */
export enum TextFieldType {
  LETTER_HEAD = 'LETTER_HEAD',
  EDITOR = 'EDITOR',
  LETTER_FOOTER = 'LETTER_FOOTER',
  INTRODUCTION_GENERAL = 'INTRODUCTION_GENERAL',
  INTRODUCTION_DETAIL_PAGE = 'INTRODUCTION_DETAIL_PAGE',
  CONCLUSION = 'CONCLUSION',
  FINAL_NOTE = 'FINAL_NOTE',
  COLOR_LEGEND_GREEN = 'COLOR_LEGEND_GREEN',
  COLOR_LEGEND_YELLOW_GREEN = 'COLOR_LEGEND_YELLOW_GREEN',
  COLOR_LEGEND_YELLOW = 'COLOR_LEGEND_YELLOW',
  COLOR_LEGEND_ORANGE = 'COLOR_LEGEND_ORANGE',
  COLOR_LEGEND_RED_ORANGE = 'COLOR_LEGEND_RED_ORANGE',
  COLOR_LEGEND_RED = 'COLOR_LEGEND_RED',
}
