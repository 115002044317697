import { IReport } from '@/shared/model/report.model';
import { ICustomer } from '@/shared/model/customer.model';
import { IAppUser } from '@/shared/model/app-user.model';

export interface IAnswer {
  id?: number;
  comment?: string | null;
  insuranceOptionDescription?: string;
  insuranceDescription?: string;
  categoryDescription?: string;
  //used for sorting
  categoryOrder?: number;
  selectedPercentage?: number;
  weightedValue?: number;
  report?: IReport | null;
  //used for lower 2 percentages to show warnings
  lowPercentage?: boolean;
  //only used on frontend for filtering
  customer: ICustomer | null;
  ignored?: boolean;
  user?: IAppUser;
}

export class Answer implements IAnswer {
  constructor(
    public id?: number,
    public comment?: string | null,
    public insuranceOptionDescription?: string,
    public insuranceDescription?: string,
    public categoryDescription?: string,
    public categoryOrder?: number,
    public selectedPercentage?: number,
    public weightedValue?: number,
    public report?: IReport | null,
    public lowPercentage?: boolean,
    public customer: ICustomer | null = null,
    public ignored?: boolean,
    public user?: IAppUser
  ) {}
}
