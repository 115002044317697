
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import CircleChart from './circle-chart.vue';
import ReportCustomerInfo from './report-customer-info.vue';
import ColorLegendV2 from '@/pages/report/components/color-legend-v2.vue';
import HorizontalCategorySection from './horizontal-category-section.vue';
import PrintPage from '@/components/print-page.vue';
import ReportHeader from './report-header.vue';
import ReportFooter from './report-footer.vue';
import { calculatePercentageForCustomer } from '@/pages/report/lib/answerCalculationService';
import { TextFieldType } from '@/shared/model/text-field-setting.model';
import { ICustomTextField } from '@/shared/model/custom-text-field.model';
import AlertService from '@/shared/alert/alert.service';
import CustomTextFieldService from '@/entities/custom-text-field/custom-text-field.service';
import TextFieldEditButton from '@/components/text-field-edit-button.vue';
import { Customer, ICustomer } from '@/shared/model/customer.model';

@Component({
  components: {
    TextFieldEditButton,
    CircleChart,
    ColorLegendV2,
    ReportCustomerInfo,
    ReportHeader,
    ReportFooter,
    PrintPage,
    HorizontalCategorySection,
  },
  filters: {
    rounded(val: number): string {
      return Math.round(val) + '';
    },
  },
})
export default class ReportDetailPageV2 extends Vue {
  @Prop({ default: 1 })
  public pagenum!: number;
  @Prop({ default: 1 })
  public maxpages!: number;
  @Prop()
  public customer: Customer;
  @Prop({ default: true })
  public showEdit: boolean;

  @Inject('alertService')
  alertService: () => AlertService;
  @Inject('customTextFieldService')
  customTextFieldService: () => CustomTextFieldService;
  @Prop()
  private answers: IAnswer[];

  get detailIntroduction(): ICustomTextField {
    const textFields = this.$store.getters.textFields;
    return textFields.find(
      item =>
        item.type === TextFieldType.INTRODUCTION_DETAIL_PAGE &&
        item.efmCustomerId === this.customer.id
    );
  }

  get detailIntroductionText() {
    const percentage = this.calcPercentageForCustomer(this.customer);
    return this.detailIntroduction?.value.replace(/{{PERCENTAGE}}/g, Math.round(percentage) + '%');
  }

  get notIgnoredAnswers() {
    return this.answers.filter(item => !item.ignored);
  }

  get logo() {
    return this.$store.getters.logo;
  }


  public findCategoriesForCustomer(): string[] {
    return this.findNotFullyIgnoredCategoriesForCustomer();
  }

  findNotFullyIgnoredCategoriesForCustomer(): string[] {
    const categories: { categoryDescription: string; categoryOrder: number }[] =
      this.notIgnoredAnswers.map(item => {
        return {
          categoryDescription: item.categoryDescription,
          categoryOrder: item.categoryOrder,
        };
      });
    //sort by order
    categories.sort((a, b) => {
      if (a.categoryOrder < b.categoryOrder) {
        return -1;
      }
      if (a.categoryOrder > b.categoryOrder) {
        return 1;
      }
      return 0;
    });
    const categoryDescriptions: string[] = categories.map(item => item.categoryDescription);
    const cats = new Set(categoryDescriptions);
    return Array.from(cats);
  }

  findForCategoryAndCustomer(category: string, customer: ICustomer): IAnswer[] {
    return this.answers.filter(
      item => item.customer.id === customer.id && item.categoryDescription === category
    );
  }

  calcPercentageForCustomer(customer: ICustomer) {
    return calculatePercentageForCustomer(this.answers, customer);
  }


}
