import { TextFieldType } from '@/shared/model/text-field-setting.model';
import { ISummaryReport } from '@/shared/model/summary-report.model';
import { ICustomer } from '@/shared/model/customer.model';

export interface ICustomTextField {
  id?: number;
  type?: TextFieldType;
  value?: string;
  summaryReport?: ISummaryReport;
  efmCustomerId: number | null;
}

export class CustomTextField implements ICustomTextField {
  constructor(
    public id?: number,
    public type?: TextFieldType,
    public value?: string,
    public summaryReport?: ISummaryReport,
    public efmCustomerId: number | null = null
  ) {}
}
