
import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import { ICustomer } from '@/shared/model/customer.model';
import { TextFieldType } from '@/shared/model/text-field-setting.model';
import { getColorHexFromPercentage } from '@/pages/report/lib/answerCalculationService';
import PrintPage from '@/components/print-page.vue';
import ReportHeader from './report-header.vue';
import ReportCustomerInfo from './report-customer-info.vue';
import AlertService from '@/shared/alert/alert.service';

import { CustomTextField, ICustomTextField } from '@/shared/model/custom-text-field.model';
import { ISummaryReport } from '@/shared/model/summary-report.model';
import CustomTextFieldService from '@/entities/custom-text-field/custom-text-field.service';
import TextFieldEditButton from '@/components/text-field-edit-button.vue';
import EfmFooter from '@/pages/report/components/efm-footer.vue';

@Component({
  methods: { getColorHexFromPercentage },
  components: {
    EfmFooter,
    TextFieldEditButton,
    PrintPage,
    ReportHeader,
    ReportCustomerInfo,
  },
})
export default class ReportCover extends Vue {
  @Inject('alertService')
  alertService: () => AlertService;
  @Inject('customTextFieldService')
  customTextFieldService: () => CustomTextFieldService;
  @Prop({ default: true })
  showEdit: boolean;
  colors = [];
  @Prop({ default: 1 })
  pagenum!: number;
  @Prop({ default: 1 })
  maxpages!: number;

  get textFields(): ICustomTextField[] {
    return this.$store.getters.textFields;
  }

  get hasFooter(): boolean {
    return this.$store.getters.footer;
  }

  get logo() {
    return this.$store.getters.logo;
  }

  get answers(): IAnswer[] {
    return this.$store.getters.answers;
  }

  get customer(): ICustomer[] {
    return this.$store.getters.customer;
  }

  get reportDate(): string {
    const report: ISummaryReport = this.$store.getters.report;
    const date = report?.createdTimestamp;
    //format to dd.MM.yyyy
    return date ? new Date(date).toLocaleDateString('de-DE') : '';
  }

  get introductionGeneral(): ICustomTextField {
    return this.textFields.find(
      (item: CustomTextField) => item.type === TextFieldType.INTRODUCTION_GENERAL
    );
  }

  get finalNoteTextField(): ICustomTextField {
    return this.$store.getters.textFields.find(
      (item: CustomTextField) => item.type === TextFieldType.FINAL_NOTE
    );
  }
}
