var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showChart
    ? _c("div", { staticClass: "text-center" }, [
        _c(
          "div",
          { staticClass: "chart-wrapper" },
          [
            _c("apexchart", {
              attrs: {
                options: _vm.chartOptions,
                series: _vm.series,
                type: "radialBar",
                height: _vm.height,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "label-wrapper",
                style: `fontSize:${_vm.fontSize}px`,
              },
              [_vm._v("\n      " + _vm._s(_vm.displayValue) + "\n    ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showName
          ? _c("h5", { style: `fontSize:${_vm.nameFontSize}px` }, [
              _vm._v(_vm._s(_vm.name)),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }