
import { Prop, Component, Vue } from 'vue-property-decorator';
import { getColorHexFromPercentage } from '@/pages/report/lib/answerCalculationService';

@Component({})
export default class CircleChart extends Vue {
  @Prop()
  val: number;
  @Prop({ default: '' })
  name: string;

  @Prop({ default: 200 })
  height: number;

  @Prop({ default: 10 })
  fontSize: number;

  @Prop({ default: false })
  showName: boolean;

  @Prop({ default: 12 })
  nameFontSize: number;

  showChart = false;

  series = [];
  chartOptions = {
    chart: {
      type: 'radialBar',
    },
    grid: {
      padding: {
        top: -20,
        bottom: -20,
      },
    },
    colors: ['#20E647'],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '35%',
        },
        dataLabels: {
          showOn: 'always',
          name: {
            show: false,
          },
          offset: {},
          value: {
            show: false,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  get displayValue() {
    if (this.val == null) {
      return '';
    }
    //round to 0 decimals
    return Math.round(this.val) + '%';
  }
  mounted() {
    if (this.val != null) {
      this.series = [Math.round(this.val)];
    }

    this.chartOptions.plotOptions.radialBar.dataLabels.name.show = false;
    this.chartOptions.chart.height = this.height;
    this.chartOptions.plotOptions.radialBar.dataLabels.value.fontSize = this.fontSize + 'px';

    const color = getColorHexFromPercentage(this.val);

    this.chartOptions.plotOptions.radialBar.dataLabels.value.color = color;
    this.chartOptions.colors = [color];
    this.chartOptions.labels = [this.name];
    this.showChart = true;
  }
}
