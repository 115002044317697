var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "info-border p-0 m-0" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "customer-info-wrapper",
        style: {
          borderLeft:
            "3px solid " + _setup.getColorHexFromPercentage(_setup.percentage),
          paddingLeft: "10px",
          marginLeft: "-10px",
        },
      },
      [
        _c("p", { staticClass: "font-weight-bold customer-info-name" }, [
          _vm._v(
            "\n      " +
              _vm._s(_setup.customer.lastName) +
              " " +
              _vm._s(_setup.customer.firstName) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _setup.customer.street && _setup.customer.zip && _setup.customer.city
          ? _c("p", { staticClass: "customer-info-street" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_setup.customer.street) +
                  ", " +
                  _vm._s(_setup.customer.zip) +
                  " " +
                  _vm._s(_setup.customer.city) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _setup.customer.job
          ? _c("p", { staticClass: "customer-info-job" }, [
              _vm._v("Beruf: " + _vm._s(_setup.customer.job)),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }