
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import { ICustomer } from '@/shared/model/customer.model';
import CircleChart from './circle-chart.vue';
import { calculatePercentageForCustomer } from '@/pages/report/lib/answerCalculationService';
import PrintPage from '@/components/print-page.vue';
import ReportHeader from './report-header.vue';
import ReportCustomerInfo from './report-customer-info.vue';
import ColorLegendV2 from '@/pages/report/components/color-legend-v2.vue';
import { ICustomTextField } from '@/shared/model/custom-text-field.model';

@Component({
  components: {
    CircleChart,
    PrintPage,
    ReportHeader,
    ReportCustomerInfo,
    ColorLegendV2,
  },
  filters: {
    rounded(val: number): string {
      return Math.round(val) + '';
    },
  },
})
export default class ReportOverviewPageV2 extends Vue {
  @Prop({ default: 1 }) pagenum!: number;
  @Prop({ default: 1 }) maxpages!: number;
  @Prop({ default: true }) showEdit: boolean;

  get textFields(): ICustomTextField[] {
    return this.$store.getters.textFields;
  }

  get logo() {
    return this.$store.getters.logo;
  }

  get answers(): IAnswer[] {
    return this.$store.getters.answers;
  }

  get customer(): ICustomer[] {
    return this.$store.getters.customer;
  }

  calcPercentageForCustomer(customer: ICustomer): number {
    return calculatePercentageForCustomer(this.answers, customer);
  }
}
