var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-wrapper" },
    [
      _vm.showEdit
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm text-field-edit-button rounded-circle",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.editTextField()
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: "pencil" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c("TextFieldDialog", {
            key: _vm.modalKey,
            attrs: { text: _vm.initialText },
            on: { cancel: _vm.handleCancel, update: _vm.handleUpdate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }