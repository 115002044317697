var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-modal", {
    ref: "editDialog",
    attrs: { title: _vm.title, id: "editDialog", "no-close-on-backdrop": "" },
    on: { hidden: _vm.onHidden },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedText,
                  expression: "updatedText",
                },
              ],
              staticClass: "form-control",
              attrs: { rows: "10" },
              domProps: { value: _vm.updatedText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.updatedText = $event.target.value
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("button", { staticClass: "btn", on: { click: _vm.cancel } }, [
              _vm._v("Abbrechen"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.updateText } },
              [_vm._v("Speichern")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }