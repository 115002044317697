var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "print-preview" }, [
    _c(
      "div",
      { staticClass: "a4" },
      [
        _vm._t("header"),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _vm._t("footer"),
        _vm._v(" "),
        _c("div", { staticClass: "page-num" }, [
          _vm._v(_vm._s(_vm.pagenum) + " / " + _vm._s(_vm.maxpages)),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }