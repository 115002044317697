var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-2" },
        [
          _c("CircleChart", {
            attrs: {
              height: 145,
              name: _vm.category,
              small: true,
              val: _vm.calcPercentageForCategoryAndCustomer(),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-10" },
        [
          _c("p", { staticClass: "font-weight-bold mb-1" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.category) +
                "\n      " +
                _vm._s(
                  _vm._f("rounded")(_vm.calcPercentageForCategoryAndCustomer())
                ) +
                " %\n    "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.sortedAnswers, function (answer, index) {
            return _c(
              "div",
              {
                key: index + _vm.customer.id + "answer",
                staticClass: "answer-insurance-wrapper",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "answer-info row",
                    staticStyle: { flex: "1" },
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "answer-insurance-description col-6" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(answer.insuranceDescription) +
                            "\n          "
                        ),
                        _vm._v(" "),
                        answer.lowPercentage
                          ? _c("font-awesome-icon", {
                              attrs: { icon: "exclamation-triangle" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "answer-option-description col-6" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(answer.insuranceOptionDescription) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    answer.comment
                      ? _c(
                          "p",
                          { staticClass: "font-italic col-12" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "circle-info" },
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(answer.comment) +
                                "\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.showEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm d-print-none",
                        staticStyle: { position: "absolute", right: "0" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.setAnswerToComment(answer)
                          },
                        },
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "pencil" } })],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", { ref: "commentEntity", attrs: { id: "commentEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _vm._v(
            "\n      Anmerkung " +
              _vm._s(_vm.answerToComment?.id ? "aktualisieren" : "hinzufügen")
          ),
        ]),
        _vm._v(" "),
        _vm.answerToComment
          ? _c("div", { staticClass: "modal-body" }, [
              _c("p", { attrs: { id: "gen-delete-percentage-heading" } }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.answerToComment.insuranceDescription)),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    " (" + _vm._s(_vm.answerToComment.categoryDescription) + ")"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "\n\n        " +
                    _vm._s(_vm.answerToComment.insuranceOptionDescription) +
                    "\n        " +
                    _vm._s(_vm.answerToComment.selectedPercentage) +
                    "%\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "comment" } }, [
                  _vm._v("Anmerkung"),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.comment,
                      expression: "comment",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "comment", rows: "3" },
                  domProps: { value: _vm.comment },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.comment = $event.target.value
                    },
                  },
                }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.cancelComment()
                },
              },
            },
            [_vm._v("Abbrechen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "gen-confirm-delete-percentage",
                "data-cy": "entityConfirmDeleteButton",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.updateComment()
                },
              },
            },
            [
              _vm._v(
                "\n        Anmerkung " +
                  _vm._s(
                    _vm.answerToComment?.id ? "aktualisieren" : "hinzufügen"
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }