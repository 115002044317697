
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import { Customer, ICustomer } from '@/shared/model/customer.model';
import CircleChart from './circle-chart.vue';
import ReportCustomerInfo from './report-customer-info.vue';
import AnswerService from '@/entities/answer/answer.service';
import AlertService from '@/shared/alert/alert.service';

@Component({
  components: { CircleChart, ReportCustomerInfo },
  filters: {
    rounded(val: number): string {
      return Math.round(val) + '';
    },
  },
})
export default class HorizontalCategorySection extends Vue {
  @Prop()
  public customer: Customer;
  @Prop()
  public category: string;
  @Prop({ default: true })
  public showEdit: boolean;
  //comment for single answer logic:
  public answerToComment: IAnswer = null;
  public comment: string = null;
  @Prop()
  public answers: IAnswer[];
  //answer service
  @Inject('answerService')
  private answerService: () => AnswerService;

  //alert service
  @Inject('alertService')
  private alertService: () => AlertService;

  get sortedAnswers(): IAnswer[] {
    return this.notIgnoredAnswers.sort((a, b) => {
      //sort by selected percentage and then by insurance description
      const diff = a.selectedPercentage - b.selectedPercentage;
      if (diff === 0) {
        return a.insuranceDescription.localeCompare(b.insuranceDescription);
      }
      return diff;
    });
  }

  get notIgnoredAnswers(): IAnswer[] {
    return this.answers.filter(item => item.ignored == null || !item.ignored);
  }

  async updateComment(): void {
    this.answerToComment.comment = this.comment;
    //if already persisted, update on the server
    if (this.answerToComment.id) {
      try {
        //do not update the variable 'this.answerToComment' because answer.customer is not set in the server update response
        const res = await this.answerService().update(this.answerToComment);
        //update summary report for updatedtimestamp
        this.answerToComment.report = res.report;
        this.alertService().showSuccess(this, 'Anmerkung erfolgreich aktualisiert');
      } catch (e) {
        this.alertService().showError(this, 'Fehler beim Speichern der Anmerkung');
        return;
      }
    }
    //update answer in store
    this.$store.commit('updateAnswer', this.answerToComment);
    this.comment = null;
    this.answerToComment = null;
    this.closeCommentDialog();
  }

  setAnswerToComment(answer: IAnswer): void {
    this.answerToComment = JSON.parse(JSON.stringify(answer));
    if (this.answerToComment.comment) {
      this.comment = this.answerToComment.comment;
    } else {
      this.comment = null;
    }
    this.showCommentDialog();
  }

  cancelComment(): void {
    this.answerToComment = null;
    this.closeCommentDialog();
  }

  showCommentDialog(): void {
    if (this.$refs.commentEntity as any) {
      (this.$refs.commentEntity as any).show();
    }
  }

  closeCommentDialog(): void {
    if (this.$refs.commentEntity as any) {
      (this.$refs.commentEntity as any).hide();
    }
  }

  calcPercentageForCategoryAndCustomer(): number {
    let sum = 0;
    this.sortedAnswers.forEach(item => {
      if (item.selectedPercentage != null) {
        sum += item.selectedPercentage;
      }
    });
    return sum / this.sortedAnswers.length;
  }
}
