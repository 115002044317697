var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "PrintPage",
    {
      attrs: { maxpages: _vm.maxpages, pagenum: _vm.pagenum },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("ReportHeader", {
                attrs: { "show-heading": false, showEdit: false },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "page-title-wrapper mb-2" }, [
          _c("h2", [
            _vm._v(
              "Risiko Check Details: " +
                _vm._s(_vm.customer.lastName) +
                " " +
                _vm._s(_vm.customer.firstName)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-field-wrapper" },
          [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.detailIntroductionText) },
            }),
            _vm._v(" "),
            _c("TextFieldEditButton", {
              attrs: {
                "show-edit": _vm.showEdit,
                "text-field-to-edit": _vm.detailIntroduction,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "details-overview-wrapper align-items-center my-3 py-3",
          staticStyle: { border: "1px solid rgba(0, 0, 0, 0.1)" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [
              _c("CircleChart", {
                staticStyle: { width: "150px" },
                attrs: {
                  height: 145,
                  showName: false,
                  val: _vm.calcPercentageForCustomer(_vm.customer),
                  name: "Gesamt",
                },
              }),
              _vm._v(" "),
              _c("ReportCustomerInfo", {
                attrs: {
                  customer: _vm.customer,
                  percentage: _vm.calcPercentageForCustomer(_vm.customer),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("ColorLegendV2", { staticClass: "col cl2 my-0" }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detail-categories-wrapper" },
        _vm._l(_vm.findCategoriesForCustomer(), function (cat, index) {
          return _c(
            "div",
            {
              key: index + _vm.customer.id + "category",
              staticClass: "mt-3 single-category-wrapper",
            },
            [
              _c("HorizontalCategorySection", {
                attrs: {
                  answers: _vm.findForCategoryAndCustomer(cat, _vm.customer),
                  category: cat,
                  customer: _vm.customer,
                  showEdit: _vm.showEdit,
                },
              }),
              _vm._v(" "),
              _c("hr"),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }