
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component({})
export default class TextFieldDialog extends Vue {
  @Prop({ required: true }) text!: string;

  @Prop({ default: 'Bearbeiten' })
  title: string;
  private updatedText = '';

  @Emit('update')
  updateText(): string {
    this.closeDialog();
    return this.updatedText;
  }
  @Emit('cancel')
  cancel(): void {
    this.closeDialog();
  }

  mounted() {
    this.updatedText = JSON.parse(JSON.stringify(this.text));
    this.openModal();
  }

  beforeDestroy() {
    this.closeDialog();
  }

  onHidden(): void {
    this.shutDown();
  }

  shutDown() {
    this.updatedText = '';
    this.cancel();
  }
  closeDialog() {
    (this.$refs.editDialog as any).hide();
  }

  openModal(): void {
    if (this.$refs.editDialog as any) {
      (this.$refs.editDialog as any).show();
    }
  }
}
